import { Badge } from '@synoptic/ui-kit/badge/badge.js';
import { StarIcon } from '@synoptic/ui-kit/icons/react/star.tsx';
import { clsx } from 'clsx';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { Wrap } from '@synoptic/ui-kit/wrap.js';
import {
  badge,
  iconContainer,
  labelContent,
  streamLabel,
} from './post-label.css.ts';

export const StreamLabel = ({
  label,
  streamId,
  className,
  linkToStream = false,
}: {
  label: string;
  streamId: string;
  className?: string;
  linkToStream?: boolean;
}) => (
  <Wrap if={linkToStream} with={Link} to={paths.makeStream(streamId)}>
    <Badge
      color={'blue'}
      className={clsx(badge, streamLabel, className)}
      interactive={linkToStream}
    >
      <div className={iconContainer}>
        <StarIcon width={16} height={16} />
      </div>
      <span className={labelContent}>{label}</span>
    </Badge>
  </Wrap>
);
